"use client";

import { create } from "@/app/actions";

export function Button() {
  return (
    <button
      onClick={() => {
        create()
          .then((res) => {
            console.log("res", res);
          })
          .catch((err) => {
            console.error("err", err);
          });
      }}
    >
      click
    </button>
  );
}
